<template>
  <section class="base-page">
    <PageHeader class="base-shadow"></PageHeader>
    <keep-alive><FilterSection @change="onFilterChange" /></keep-alive>
    <BasicTable
      showSelection
      hiddenClear
      :loading="tableLoading"
      :tableColumns="tableColumns"
      :tableData="tableData"
      :page="page"
      :size="size"
      :total="total"
      :orderDirection="orderDirection"
      :orderProp="orderProp"
      @onSortChange="onTableSortChange"
      @onSizeChange="handleSizeChange"
      @onPageChange="handlePageChange"
      @onRefresh="onRefresh"
      @onSelectionChange="onSelectionChange"
    >
      <template #tableControl>
        <el-button
          class="mt-r"
          size="small"
          type="primary"
          @click="addUser"
          v-if="checkPermission(['USER_ADMIN'])"
          >新增</el-button
        >
      </template>
      <template v-for="column in tableColumns">
        <el-table-column
          v-if="column.prop !== 'controlColumn' && column.showColumn"
          v-bind="column"
          :show-overflow-tooltip="true"
          :key="column.prop"
        >
          <template slot-scope="scope">
            <span v-if="column.prop === 'createdAt'">{{
              scope.row.createdAt | date_time
            }}</span>
            <span v-else-if="column.prop === 'name'">
              <span
                style="padding-left: 5px"
                title="点击查看详情"
                class="text-nav"
                @click="onShowDetail(scope.row)"
                >{{ scope.row.name }}</span
              >
            </span>

            <span v-else-if="column.prop === 'activated'">
              <el-tag type="success" v-if="scope.row.activated">已激活</el-tag>
              <el-tag type="danger" v-if="!scope.row.activated">未激活</el-tag>
            </span>

            <span v-else-if="column.prop === 'disabled'">
              <el-tag type="success" v-if="!scope.row.disabled">已启用</el-tag>
              <el-tag type="danger" v-if="scope.row.disabled">已禁用</el-tag>
            </span>

            <span v-else-if="column.prop === 'entWxBound'">
              <el-tag type="success" v-if="scope.row.entWxBound">已绑定</el-tag>
              <el-tag type="danger" v-if="!scope.row.entWxBound">未绑定</el-tag>
            </span>

            <span v-else-if="column.prop === 'roles'">
              <span v-if="scope.row.roles.length <= 0">/</span>
              <el-popover v-else placement="left" width="300" trigger="click">
                <el-tag
                  class="tag"
                  v-for="item in scope.row.roles"
                  :key="item.id"
                  type="info"
                  style="margin-right: 10px; margin-bottom: 10px"
                >
                  <i class="el-icon-paperclip"></i>
                  {{ item.name }}
                </el-tag>
                <el-button slot="reference" type="text" icon="el-icon-view"
                  >{{ scope.row.roles[0].name }}（{{
                    scope.row.roles.length
                  }}个）</el-button
                >
              </el-popover>
            </span>

            <span v-else>{{ scope.row[column.prop] }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="column.prop === 'controlColumn' && column.showColumn"
          v-bind="column"
          :key="column.prop"
        >
          <template slot-scope="scope">
            <el-button
              :loading="scope.row.canUpdateChecking"
              @click.native.prevent="editRow(scope.row, scope.$index)"
              type="text"
              size="small"
            >
              {{ scope.row.canUpdateChecking ? "权限检验中..." : "修改" }}
            </el-button>
            <el-button
              class="text-danger"
              v-if="!scope.row.disabled"
              :loading="scope.row.canDisableChecking"
              @click.native.prevent="disableRow(scope.row, scope.$index)"
              type="text"
              size="small"
            >
              {{ scope.row.canDisableChecking ? "权限检验中..." : "禁用" }}
            </el-button>
            <el-button
              class="text-success"
              v-if="scope.row.disabled"
              :loading="scope.row.canDisableChecking"
              @click.native.prevent="enableRow(scope.row, scope.$index)"
              type="text"
              size="small"
            >
              {{ scope.row.canDisableChecking ? "权限检验中..." : "启用" }}
            </el-button>
            <el-dropdown v-if="checkPermission(['USER_ADMIN'])">
              <span class="el-dropdown-link drop-down-menu-link">
                更多操作<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-if="checkPermission(['USER_ADMIN'])"
                  :disabled="scope.row.canUpdatePasswordChecking"
                  @click.native.prevent="editPassword(scope.row, scope.$index)"
                  >{{
                    scope.row.canUpdatePasswordChecking
                      ? "权限检验中..."
                      : "修改密码"
                  }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </template>
      <template #multipleControl>
        <el-button
          size="small"
          @click="onMultipleEntWxUpdate(false)"
          :disabled="multipleSelected.length <= 0"
          >批量绑定企业微信{{ multipleSelected.length }}</el-button
        >
        <el-button
          v-if="checkPermission(['USER_ADMIN'])"
          type="success"
          size="small"
          @click="onMultipleToggleDisable(false)"
          :disabled="multipleSelected.length <= 0"
          :loading="multipleUndisableLoading"
          >{{
            multipleUndisableLoading ? multipleDisableLoadingText : "批量启用"
          }}
          {{ multipleSelected.length }}</el-button
        >
        <el-button
          type="danger"
          size="small"
          v-if="checkPermission(['USER_ADMIN'])"
          @click="onMultipleToggleDisable(true)"
          :disabled="multipleSelected.length <= 0"
          :loading="multipleDisableLoading"
          >{{
            multipleDisableLoading ? multipleDisableLoadingText : "批量禁用"
          }}
          {{ multipleSelected.length }}</el-button
        >
      </template>
      <el-dialog
        :title="isEmprty(currentId) ? '新增用户' : '修改用户'"
        :visible.sync="formDialog"
        append-to-body
        :close-on-click-modal="false"
        width="1000px"
      >
        <Form :id="currentId" @cancel="onFormCancel" @success="onFormSuccess" />
      </el-dialog>
      <el-dialog
        title="用户详情"
        :visible.sync="detailDialog"
        append-to-body
        width="900px"
      >
        <Detail :id="currentId" />
      </el-dialog>
      <el-dialog
        title="修改密码"
        append-to-body
        :close-on-click-modal="false"
        :visible.sync="editPasswordDialog"
        width="300"
      >
        <PasswordForm
          :id="currentId"
          @cancel="onFormCancel"
          @success="onFormSuccess"
        />
      </el-dialog>
      <el-dialog
        title="绑定企业微信"
        append-to-body
        :close-on-click-modal="false"
        :visible.sync="bindEntWxDialog"
        width="600"
      >
        <EntWxMultipleForm
          :userData="multipleSelected"
          @cancel="onEntWxUpdateCancel"
          @success="onEntWxUpdateSuccess"
        />
      </el-dialog>
    </BasicTable>
  </section>
</template>

<script>
import BasicTable from "@/components/BasicTable";
import Form from "./components/Form";
import EntWxMultipleForm from "./components/EntWxMultipleForm";
import Detail from "./components/Detail";
import PasswordForm from "./components/PasswordForm";
import { ShowApiError } from "@/request/error";
import { getOrderType } from "@/utils/common";
import { isEmprty } from "@/utils/validate";
import { checkPermission } from "@/utils/auth";
import FilterSection from "./components/FilterSection";
import {
  GetUsersList,
  GetUpdateCheck,
  GetUpdatePasswordCheck,
  // GetWxEntUserIdUpdateCheck,
} from "./api";
import PageHeader from "@/components/PageHeader";
import commonControl from "./mixins/commonControl";
import { ReadLocalColumn } from "@/utils/table";
import config from "@/config";
export default {
  mixins: [commonControl],
  components: {
    BasicTable,
    Form,
    EntWxMultipleForm,
    PasswordForm,
    FilterSection,
    PageHeader,
    Detail,
  },
  data() {
    return {
      formDialog: false,
      tableLoading: false,
      bindEntWxDialog: false,
      editPasswordDialog: false,
      detailDialog: false,
      tableColumns: [],
      page: 0,
      size: config.TABLE_SIZE,
      total: 0,
      tableData: [],
      tableFilters: {
        createdAt: null,
        lastModifiedAt: null,
        departmentId: null,
        rolesId: null,
        name: "",
        phoneNo: "",
      },
      orderProp: "createdAt",
      orderDirection: "descending",
      currentId: null,
      multipleSelected: [],
      orderPropObj: {},
    };
  },
  created() {
    this.buildTableColumns();
    this.getTableData();
  },
  methods: {
    isEmprty,
    checkPermission,
    buildTableColumns(columns = []) {
      if (columns.length > 0) {
        this.tableColumns = columns;
      } else {
        let columns = [
          {
            prop: "activated",
            label: "是否已激活",
            width: 120,
            align: "center",
            showColumn: true,
            fixed: "left",
            sortable: "custom",
          },
          {
            prop: "disabled",
            label: "是否禁用",
            showColumn: true,
            width: 110,
            align: "center",
            fixed: "left",
            sortable: "custom",
          },
          {
            prop: "name",
            label: "用户姓名",
            minWidth: 130,
            showColumn: true,
            align: "left",
            fixed: "left",
            sortable: "custom",
          },
          {
            prop: "username",
            label: "用户登录名",
            minWidth: 130,
            showColumn: true,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "phoneNo",
            label: "电话号码",
            align: "left",
            minWidth: 130,
            showColumn: true,
            sortable: false,
          },
          {
            prop: "departmentName",
            label: "部门名称",
            showColumn: true,
            minWidth: 130,
            sortable: "custom",
          },
          {
            prop: "entWxUserId",
            label: "企业微信",
            showColumn: true,
            minWidth: 130,
            sortable: "custom",
          },

          {
            prop: "roles",
            label: "角色",
            showColumn: true,
            minWidth: 160,
            sortable: "custom",
          },
          {
            prop: "createdAt",
            label: "创建时间",
            showColumn: true,
            width: 200,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "createdByName",
            label: "创建人",
            showColumn: true,
            minWidth: 150,
            sortable: "custom",
          },

          {
            prop: "controlColumn",
            fixed: "right",
            showColumn: true,
            label: "操作",
            width: 160,
          },
        ];
        this.tableColumns = ReadLocalColumn(this.$route, columns);
      }
    },
    handleSizeChange(val) {
      this.page = 0;
      this.size = val;
      this.getTableData();
    },
    handlePageChange(val) {
      this.page = val - 1;
      this.getTableData();
    },
    filterParams() {
      return {
        ...this.tableFilters,
      };
    },
    getTableOrderParams() {
      let orderPropName = "";
      if (this.orderProp !== "" && this.orderDirection !== null) {
        orderPropName = Object.prototype.hasOwnProperty.call(
          this.orderPropObj,
          this.orderProp
        )
          ? this.orderPropObj[this.orderProp]
          : this.orderProp;
        return `${orderPropName},${getOrderType(this.orderDirection)}`;
      } else {
        return null;
      }
    },
    getControlColumn() {
      return {
        canDisableChecking: false,
        canUpdateChecking: false,
        canUpdatePasswordChecking: false,
        canUpdatePassword: false,
        canUpdate: false,
        canDisable: false,
        canBindEntWx: true,
      };
    },
    getTableData() {
      this.tableLoading = true;
      GetUsersList({
        page: this.page,
        size: this.size,
        sort: this.getTableOrderParams(),
        ...this.filterParams(),
      })
        .then((res) => {
          let { content, totalElements } = res.data;
          this.tableData = content.map((item) => {
            return {
              ...item,
              ...this.getControlColumn(),
            };
          });
          // this.tableData.forEach((item) => {
          //   this.handleCheckBindEntWx(item);
          // });
          this.total = totalElements;
          // 加载状态结束
          this.tableLoading = false;
        })
        .catch((err) => {
          this.tableLoading = false;
          ShowApiError("拉取列表错误", err);
        });
    },
    onRefresh() {
      this.getTableData();
    },
    onTableSortChange({ prop, order }) {
      if (order === null) {
        this.orderProp = "createdAt";
        this.orderDirection = "descending";
      } else {
        this.orderDirection = order;
        this.orderProp = prop;
      }
      this.page = 0;
      this.onRefresh();
    },
    onSelectionChange(selections) {
      this.multipleSelected = selections;
    },
    onFilterChange(data) {
      this.tableFilters = data;
      this.page = 0;
      this.onRefresh();
    },
    addUser() {
      this.currentId = null;
      this.formDialog = true;
    },
    // handleCheckBindEntWx(item) {
    //   GetWxEntUserIdUpdateCheck([item.id])
    //     .then((res) => {
    //       item.canBindEntWx = res.data;
    //     })
    //     .catch(() => {
    //       item.canDisable = true;
    //     });
    // },
    // 修改单行
    editRow(row) {
      if (row.canUpdate) {
        this.currentId = row.id;
        this.formDialog = true;
        return false;
      }
      row.canUpdateChecking = true;
      GetUpdateCheck(row.id)
        .then((res) => {
          if (res.data) {
            row.canUpdate = res.data;
            this.currentId = row.id;
            this.formDialog = true;
          } else {
            this.currentId = null;
            this.$message.warning("没有权限执行此操作!");
          }
        })
        .catch(() => {
          row.canUpdate = false;
          this.$message.warning("权限校验异常，请稍后再试");
        })
        .finally(() => {
          row.canUpdateChecking = false;
        });
    },
    // 修改密码
    editPassword(row) {
      if (row.canUpdatePassword) {
        this.currentId = row.id;
        this.editPasswordDialog = true;
        return false;
      }
      row.canUpdatePasswordChecking = true;
      GetUpdatePasswordCheck(row.id)
        .then((res) => {
          row.canUpdatePassword = res.data;
          row.canUpdatePasswordChecking = false;
          if (res.data) {
            this.currentId = row.id;
            this.editPasswordDialog = true;
          } else {
            this.currentId = null;
            this.editPasswordDialog = false;
            this.$message.warning("没有权限执行此操作!");
          }
        })
        .catch(() => {
          row.canUpdatePasswordChecking = false;
          this.$message.warning("权限校验异常，请稍后再试");
        });
    },
    onShowDetail(row) {
      this.currentId = row.id;
      this.detailDialog = true;
    },
    disableRow(row, index) {
      this.ToggleDisableRow(row, true).then((res) => {
        this.$set(this.tableData, index, {
          ...this.getControlColumn(),
          ...res.data,
        });
      });
    },
    enableRow(row, index) {
      this.ToggleDisableRow(row, false).then((res) => {
        this.$set(this.tableData, index, {
          ...this.getControlColumn(),
          ...res.data,
        });
      });
    },

    onMultipleEntWxUpdate() {
      this.bindEntWxDialog = true;
    },

    onEntWxUpdateCancel() {
      this.bindEntWxDialog = false;
    },
    onEntWxUpdateSuccess() {
      this.bindEntWxDialog = false;
      this.onRefresh();
    },

    onFormCancel() {
      this.currentId = null;
      this.formDialog = false;
      this.editPasswordDialog = false;
    },
    onFormSuccess() {s
      this.formDialog = false;
      this.editPasswordDialog = false;
      if (!isEmprty(this.currentId)) {
        // 修改模式下只更新当页数据
        this.onRefresh();
      } else {
        // 新增模式下，新增成功后返回第一页
        this.page = 0;
        this.onRefresh();
      }
      this.currentId = null;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>